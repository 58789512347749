<template>
  <v-app>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card card-custom">
          <div class="card-body p-0">
            <!--begin: Wizard-->
            <div
              class="wizard wizard-1"
              id="kt_wizard_v1"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Body-->
              <div
                class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
              >
                <div class="col-xl-12">
                  <!--begin: Wizard Form-->
                  <form class="form" @submit.stop.prevent="formOnsubmit()">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <h3
                        class="
                          font-weight-bolder
                          text-dark
                          font-size-h4 font-size-h1-lg
                        "
                      >
                        Buat Kegiatan Akademik
                      </h3>
                      <b-form-group
                        id="input-group-name"
                        label="Nama:"
                        label-for="input-name"
                      >
                        <b-form-input
                          id="input-name"
                          v-model="form.name"
                          placeholder="Nama Kegiatan"
                        ></b-form-input>
                        <small class="text-danger">{{ error.name }}</small>
                      </b-form-group>

                      <b-form-group
                        id="input-group-school-year"
                        label="Tahun Ajaran:"
                        label-for="input-school-year"
                      >
                        <treeselect
                          v-model="form.school_year_id"
                          :multiple="false"
                          placeholder="Pilih Tahun Ajaran"
                          :options="school_years"
                        />
                        <small class="text-danger">{{
                          error.school_year_id
                        }}</small>
                      </b-form-group>

                      <!-- <b-form-group
                        label="Absensi:"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-form-radio-group
                          id="radio-group-absent"
                          v-model="form.is_absent"
                          :options="is_absent_option"
                          :aria-describedby="ariaDescribedby"
                          name="radio-absent"
                        ></b-form-radio-group>
                        <small class="text-danger">{{ error.is_absent }}</small>
                      </b-form-group> -->

                      <!-- <b-form-group
                        label="Masuk/Libur:"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-form-radio-group
                          id="radio-group-school"
                          v-model="form.is_school"
                          :options="is_school_option"
                          :aria-describedby="ariaDescribedby"
                          name="radio-school"
                        ></b-form-radio-group>,
                        <small class="text-danger">{{ error.is_school }}</small>
                      </b-form-group> -->

                      <b-form-group
                        id="input-group-start-periode"
                        label="Awal Kegiatan:"
                        label-for="input-start-periode"
                      >
                        <v-dialog
                          ref="dialog"
                          v-model="modal_start_periode"
                          :return-value.sync="form.start_date"
                          persistent
                          width="350px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <b-form-input
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              id="input-trigger-modal-start-periode"
                              v-model="form.start_date"
                              placeholder="Awal Kegiatan"
                            >
                            </b-form-input>
                          </template>

                          <v-date-picker
                            v-if="modal_start_periode"
                            v-model="form.start_date"
                            locale="id"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal_start_periode = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(form.start_date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </b-form-group>
                      <b-form-group
                        id="input-group-end-periode"
                        label="Akhir Kegiatan:"
                        label-for="input-end-periode"
                      >
                        <v-dialog
                          ref="dialogend"
                          v-model="modal_end_periode"
                          :return-value.sync="form.due_date"
                          persistent
                          width="350px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <b-form-input
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              id="input-trigger-modal-end-periode"
                              v-model="form.due_date"
                              placeholder="Akhir Kegiatan"
                            >
                            </b-form-input>
                          </template>

                          <v-date-picker
                            v-if="modal_end_periode"
                            v-model="form.due_date"
                            locale="id"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal_end_periode = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialogend.save(form.due_date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </b-form-group>

                      <b-form-group id="input-group-description">
                        <label for="input-description"
                          >Deskripsi:
                          <em class="text-muted">opsional</em></label
                        >
                        <b-form-textarea
                          id="input-description"
                          v-model="form.description"
                          placeholder="Deskripsi Kegiatan"
                          rows="4"
                          max-rows="8"
                        ></b-form-textarea>
                        <small class="text-danger">{{
                          error.description
                        }}</small>
                      </b-form-group>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div>
                        <b-button type="submit" variant="primary"
                          >Simpan</b-button
                        >
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="default"
                          @click="$router.push('/academic-calendars')"
                        >
                          Batal
                        </b-button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </form>
                  <!--end: Wizard Form-->
                </div>
              </div>
              <!--end: Wizard Body-->
            </div>
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      form: {
        name: "",
        start_date: new Date().toISOString().substr(0, 10),
        due_date: new Date().toISOString().substr(0, 10),
        school_year_id: "",
        is_school: 1,
        is_absent: 1,
        description: "",
      },
      error: {
        name: "",
        start_date: "",
        due_date: "",
        school_year_id: "",
        is_school: "",
        is_absent: "",
        description: "",
      },
      modal_start_periode: false,
      modal_end_periode: false,
      school_years: [],
      is_school_option: [
          { text: 'Masuk Sekolah', value: 1 },
          { text: 'Libur Sekolah', value: 0 },
      ],
      is_absent_option: [
          { text: 'Ada absensi', value: 1 },
          { text: 'Tidak ada absensi', value: 0 },
      ],
    };
  },
  methods: {
    async getSchoolYearOption() {
      let response = await module.setTreeSelect("api/school-years");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.school_years = response.data;
        this.school_years.unshift({
          label: "Pilih Tahun Ajaran",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getSchoolYearActive() {
      let today = new Date().toISOString().substr(0, 10)
      let filterParams = `now=${today}`;
      let response = await module.paginate(
        "api/school-years",
        `?${filterParams}`
      );
      //("response", response)
      this.form.school_year_id = response.data[0].id
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, "api/academic-calendars");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
        title: response.success.title,
        text: response.success.message,
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
        this.$router.push("/academic-calendars");
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/settings" },
      { title: "Kalender Akademik", route: "/academic-calendars" },
      { title: "Tambah Kegiatan Akademik" },
    ]);

    this.getSchoolYearOption();
    this.getSchoolYearActive()
  },
};
</script>